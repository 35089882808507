.badge {
  font-weight: bold;
  letter-spacing: var(--letter-spacing-loose);
  font-size: 8px;
  line-height: 11px;
  text-transform: uppercase;
  border-radius: 15px;
  margin-left: 5px;
  height: 15px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
}
