@mixin sideBarMenuNotch() {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  height: 30px;
  width: 4px;
}

.marqueeContainer {
  display: block;
  margin: 30px 72px 31px 32px;
}

.giveFeedbackContainer {
  a,
  div {
    font-size: var(--font-size-4);
    font-weight: normal;
  }
}

.nav {
  min-width: 180px;
  overflow-x: hidden;

  &.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &.isDesktop {
    padding-top: 18px;
  }
}

.topSection {
  overflow-y: hidden;

  &.bottomPadding {
    overflow-y: scroll;
    padding-bottom: 16px;
  }
}

.bottomMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: var(--leftSide-width-px);
  background-color: var(--content-area-background-color-hex);
  padding-top: 30px;

  &.branchBannerVisible {
    padding-bottom: var(--branch-banner-height);
  }

  &.fixedToBottom {
    padding: 16px 0 28px;

    &.branchBannerVisible {
      padding-bottom: calc(28px + var(--branch-banner-height));
    }
  }

  &.playerVisible {
    bottom: var(--player-height);
  }

  &.scrollShadow {
    box-shadow: var(--fade-box-shadow);
  }

  .navigationMenuItem {
    font-weight: bold;
    line-height: var(--font-size-3);
    color: var(--secondary-color-5);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-loose);
    text-transform: uppercase;
  }
}

.navigationMenuItem {
  composes: link from '../../styles/common.module.scss';
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-1);
  line-height: 1;
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
  color: var(--cloud-grey);
  padding-left: 17px;
  padding-top: 3px;
  padding-bottom: 3px;
  transition: var(--base-transition);
  border-left: 4px solid transparent;

  &:hover,
  &:focus {
    cursor: pointer;
    color: var(--knighthood-grey);

    use,
    mask {
      fill: var(--knighthood-grey);
    }

    path {
      stroke: var(--knighthood-grey);
    }

    &::after {
      @include sideBarMenuNotch;

      background: var(--knighthood-grey);
    }
  }

  &:active {
    cursor: pointer;
    color: var(--secondary-color-5);

    use,
    mask {
      fill: var(--secondary-color-5);
    }

    path {
      stroke: var(--secondary-color-5);
    }
  }

  &.activeMenuItem {
    color: var(--secondary-color-5);

    &::after {
      @include sideBarMenuNotch;

      background: var(--secondary-color-5);
    }

    use,
    mask {
      fill: var(--secondary-color-5);
    }

    path {
      stroke: var(--secondary-color-5);
    }
  }

  &.topMargin {
    margin-top: 16px;
  }
}

.navigationMenuIcon {
  padding-right: 10px;
  height: var(--large-icon-size);
}

.navigationMenuItemWrapper {
  position: relative;
}

.searchMenuItem {
  padding-bottom: 4px;
}

.upsellButtonContainer {
  width: 100%;
  margin: 25px 0;

  // HTML changes based on component
  a,
  button {
    display: block;
    width: 156px;
    height: 42px;
    margin-left: 32px;
  }

  button > span {
    display: inline;
  }
}

.desktopLinkContainer {
  width: 100%;
  margin-top: 0;
  position: relative;
}
